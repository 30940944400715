import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import '../../assets/css/documents.css'
import Axios from 'axios'
import Global from '../../inc/Global'
import configs from '../../inc/helpers'
import InputField from '../InputField'
import classnames from 'classnames'
import debounce from 'lodash.debounce'
import PlusCircle from '../icons/PlusCircle'
import Lightbox from 'react-image-lightbox';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import 'react-image-lightbox/style.css';

class Documents extends Component {
    constructor(props){
        super(props);

        this.state = {
            folder : this.props.folder ? this.props.folder : null,
            filesList : [],
            allFilesList : [],
            limit : 15,
            step : 10,
            startFrom : 0,
            loaded : false,
            foldername: "",
            addFolder : false,
            addFiles : false,
            errors : [],
            filesToAdd : [],
            filesToAddNames : [],
            addFilesError: "",
            uploadPercentage: 0,
            allLoaded : false,
            fileToDelete : false,
            folderToDelete : false,
            sortBy : "type",
            order : "asc",
            apiError: "",
            lightBox:{
                open: false
            }
        };

        this.AddFolderBtn = React.createRef();
        this.AddFilesBtn = React.createRef();
        this.InputFiles = React.createRef();
        this.searchB = React.createRef();
        this.DeleteFileBtn = React.createRef();
        this.DeleteFolderBtn = React.createRef();

        this.handleDocxChange = this.handleDocxChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSort = this.handleSort.bind(this);

        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.filesList.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }

    componentDidUpdate(prevProps){
        if(prevProps.folder !== this.props.folder){
            const folderId = this.props.folder ? this.props.folder : null;

            this.setState({
                folder : folderId,
            })

            const data = new FormData();
            data.append('email', this.props.auth.user.username);
            data.append('start', this.state.startFrom);
            data.append('nb', this.state.limit);
            data.append('idossier', folderId);
            
            Axios
            .post(Global.API_URL + "/api/listfile", data)
            .then((res) => {
                let filesAndFolders = [];
                res.data.Fichiers.forEach(file => {
                    file['type'] = 'file';
                    filesAndFolders.push(file);
                });
                res.data.Dossier.forEach(folder => {
                    folder['type'] = 'folder';
                    filesAndFolders.push(folder);
                });
                filesAndFolders.sort(function(a, b){
                    return a.createdAt > b.createdAt
                });

                filesAndFolders.sort(function(a, b){
                    return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                })



                if(this.state.limit >= filesAndFolders.length){
                    this.setState({
                        allLoaded : true,
                        filesList : filesAndFolders,
                        allFilesList : filesAndFolders,
                        loaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false,
                        filesList : filesAndFolders,
                        allFilesList : filesAndFolders,
                        loaded : true
                    })
                }
            })
            .catch((err) => {
                // console.log(err);
            });
        }
    }

    componentDidMount(){
        const data = new FormData();
        data.append('email', this.props.auth.user.username);
        data.append('start', this.state.startFrom);
        data.append('nb', this.state.limit);
        data.append('idossier', this.state.folder);
        
        Axios
        .post(Global.API_URL + "/api/listfile", data)
        .then((res) => {
            let filesAndFolders = [];
            res.data.Fichiers.forEach(file => {
                file['type'] = 'file';
                filesAndFolders.push(file);
            });
            res.data.Dossier.forEach(folder => {
                folder['type'] = 'folder';
                filesAndFolders.push(folder);
            });
            filesAndFolders.sort(function(a, b){
                return a.createdAt > b.createdAt
            });

            filesAndFolders.sort(function(a, b){
                return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
            })

            // console.log(filesAndFolders)

            if(this.state.limit >= filesAndFolders.length){
                this.setState({
                    allLoaded : true,
                    filesList : filesAndFolders,
                    allFilesList : filesAndFolders,
                    loaded : true
                })
            }else{
                this.setState({
                    allLoaded : false,
                    filesList : filesAndFolders,
                    allFilesList : filesAndFolders,
                    loaded : true
                })
            }
        })
        .catch((err) => {
            // console.log(err);
        });
        

    }
    handleInputChange (e){
        
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);
        
        const files = this.state.allFilesList.filter(item =>
            (item.documentreelname && item.documentreelname.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.document && item.document.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.nom && item.nom.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.nomauteur && item.nomauteur.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.prenomauteur && item.prenomauteur.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(event.target.value.toLowerCase()))
        );

        await this.setState({ 
            filesList: files,
        });
        


        if(this.state.limit >= this.state.filesList.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    cancel(e){
        e.preventDefault();
        this.setState({
            addFolder : false,
            addFiles : false,
            fileToDelete : false,
            folderToDelete : false,
            filesToAddNames : [],
            filesToAdd : []
        })
    }
    confirmFileDeletion(e){
        e.preventDefault();
        var btn = this.DeleteFileBtn;
        btn.current.classList.add('on');
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('idfile', this.state.fileToDelete)
        data.append('isracine', this.state.folder ? true : false) 

        Axios
        .post(Global.API_URL+"/api/deletefile", data)
        .then((res) => {
            // console.log(res);
            btn.current.classList.remove('on');

            var files = [...this.state.filesList]; 
            var AllFiles = [...this.state.allFilesList];
    
            var currentFile = configs.getIndexWidthProperty(files, 'id', this.state.fileToDelete);
            var currentAllFile = configs.getIndexWidthProperty(AllFiles, 'id', this.state.fileToDelete);;
            
            

            files.splice(currentFile, 1)
            AllFiles.splice(currentAllFile, 1) 
            
            this.setState({
                fileToDelete : null,
                filesList : files,
                allFilesList : AllFiles
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
    }
    confirmFolderDeletion(e){
        e.preventDefault();
        var btn = this.DeleteFolderBtn;
        btn.current.classList.add('on');
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('idossier', this.state.folderToDelete)

        Axios
        .post(Global.API_URL+"/api/deletedossierbib", data)
        .then((res) => {
            
            btn.current.classList.remove('on');

            var files = [...this.state.filesList]; 
            var AllFiles = [...this.state.allFilesList];
            
            // console.log(files);//----------------------

            var currentFile = configs.getIndexWidthProperty(files, 'id', this.state.folderToDelete);
            var currentAllFile = configs.getIndexWidthProperty(AllFiles, 'id', this.state.folderToDelete);
            
            

            files.splice(currentFile, 1)
            AllFiles.splice(currentAllFile, 1)

            // console.log(files);//-----------------------

            this.setState({
                folderToDelete : false,
                filesList : files,
                allFilesList : AllFiles
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                folderToDelete : false
            });
        });
    }
    handleAddFolder(e){
        e.preventDefault();
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('nomdossier', this.state.foldername)
        data.append('idossier', this.state.folder)
        let btn = this.AddFolderBtn.current;
        btn.classList.add('on');
        Axios
        .post(Global.API_URL + '/api/createdossier', data)
        .then((res) => {
            
            if(res.data[0].status){
                
                this.componentDidMount();

                this.setState({
                    addFolder : false
                })
                
            }else{
                this.setState({
                    ...this.state,
                    apiError: res.data[0]
                }, ()=>{
                    setTimeout(()=>{
                        this.setState({
                            ...this.state,
                            apiError: ""
                        })
                    },5000)
                })
            }
            btn.classList.remove('on');
        })
        .catch((err) => {
            btn.classList.remove('on');
            // console.log(err.message);
        })

        return;
    }
    handleAddFiles(e){
        e.preventDefault();
        if(this.state.filesToAdd.length <= 0){
            this.setState({
                addFilesError: "Il faut sélectionner au moin un fichier"
            })
            return;
        }
        if(this.state.filesToAdd.length > 5){
            return;
        }
        let btn = this.AddFilesBtn.current;
        btn.classList.add('on');
        
        this.setState({
            addFilesError : ""
        })

        const data = new FormData();
        data.append('email', this.props.auth.user.username) 

        if( this.state.filesToAdd.length > 1 ){
            this.state.filesToAdd.forEach((el, index) => {
                data.append('document['+index+']', el)
            });
        }else{
            data.append('document', this.state.filesToAdd[0])
        }
        
        var API
        if(this.state.folder){
            data.append('idoc', this.state.folder);
            API = '/api/ajoutfiledossier';
        }else{
            API = '/api/ajoutfile';
        }

        
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent < 100 ){
                    this.setState({ uploadPercentage: percent })
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        Axios
        .post(Global.API_URL + API, data, options)
        .then((res) => {
            
            if(res.data[0].status){
                this.componentDidMount();
                
                this.setState({
                    ...this.state,
                    addFiles : false
                });
            }
            btn.classList.remove('on');
            
        })
        .catch((err) => {
            btn.classList.remove('on');
            // console.log(err.message);
        })

        return;
    }
    handleDocxChange(e){
        var filesToAddNames;
        var filesToAdd;

        
        filesToAddNames = this.state.filesToAddNames;
        filesToAdd = this.state.filesToAdd;
        

        for(var i = 0; i < e.target.files.length; i++) {
            var fileName = e.target.files[i].name;
            var ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()

            filesToAddNames.push(fileName);
            filesToAdd.push(e.target.files[i]);
        }

        this.setState({
            filesToAddNames: filesToAddNames,
            filesToAdd : filesToAdd
        })
        
        this.InputFiles.current.value = null;
    }
    removeFile(ev){
        var target = ev.target.getAttribute('data-target');
        var filesToAddNames = this.state.filesToAddNames;
        var filesToAdd = this.state.filesToAdd;

        filesToAddNames.splice(target, 1);
        filesToAdd.splice(target, 1);

        this.setState({
            filesToAddNames : filesToAddNames,
            filesToAdd : filesToAdd
        })
    }
    handleSort(a, b){
        let TypeA = "AAfolder";
        let TypeB = "AAfolder";
        let field1 = "";
        let field2 = "";

        if(a.type === 'file'){
            TypeA = a.document ? a.document.substring(a.document.lastIndexOf(".")+1).toLowerCase() : "txt";
        }

        if(b.type === 'file'){
            TypeB = b.document ? b.document.substring(b.document.lastIndexOf(".")+1).toLowerCase() : "txt";
        }

        
        if(this.state.sortBy === "name"){

            if(a.nom){
                field1 = a.nom.toLowerCase();
            }else if(a.document){
                field1 = a.document.toLowerCase();
            }
            
            if(b.nom){
                field2 = b.nom.toLowerCase();
            }else if(b.document){
                field2 = b.document.toLowerCase();
            }
        }else if(this.state.sortBy === "date"){
            
            field1 = new Date(a.createdAt);
            field2 = new Date(b.createdAt);
            
        }else{
            field1 = TypeA;
            field2 = TypeB
        }

        if(this.state.order === "asc"){
            return field1 > field2 ? 1 : -1;
        }else{
            return field1 <= field2 ? 1 : -1;
        }

            
        // if(b.type == "folder"){
        // }else{
        //     var field1 = "";
        //     var field2 = "";

        //     if(a.nom){
        //         field1 = a.nom.toLowerCase();
        //     }else if(a.document){
        //         field1 = a.document.toLowerCase();
        //     }

        //     if(b.nom){
        //         field2 = b.nom.toLowerCase();
        //     }else if(b.document){
        //         field2 = b.document.toLowerCase();
        //     }
            
        //     return field1.toLowerCase() > field2.toLowerCase() ? 1 : -1;

        // }
    }

    render() {
        
        return (
            <div className="content">
                <div className="wrap"> 
                    <div className="header-list">
                        <div className="d-flex jc-space-b align-center">
                            <h3 className="h3"> 
                                <center>
                                <Link to="" onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                        <span className="fa fa-long-arrow-left"></span>
                                    </Link> 
                                    Mes documents
                                </center>
                            </h3>  
                        </div>
                    </div> 
                    <div className="documents"> 
                        

                        <div className="documents-action-buttons"> 
                            <Link to="/" className="button" onClick={(e) => {e.preventDefault(); this.setState({addFolder : true, foldername: ""})}}>
                                Créer un dossier  
                                {/* <PlusCircle /> */}
                            </Link>
                            <Link to="/" className="button" onClick={(e) => {e.preventDefault(); this.setState({addFiles : true, filesToAddNames: [], filesToAdd: [], uploadPercentage: 0});}} title="Maximum de 5 fichiers à télécharger">
                                Ajouter un document 
                                {/* <PlusCircle /> */}
                            </Link>
                            <Link to="/" className="button btn-filter" onClick={(e) => {e.preventDefault(); this.setState({showFilter : !this.state.showFilter})}}>
                                <i className="fa fa-search"></i> 
                                {/* <PlusCircle /> */}
                            </Link>
                        </div>

                        {
                            this.state.showFilter &&
                            <div className="_srs-users">
                                <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Recherche"/>
                                <Link to="" onClick={(e) => {
                                        e.preventDefault();
                                        this.searchB.current.setAttribute('val', ""); 
                                        this.searchB.current.value = ""; 
                                        this.setState({filesList : this.state.allFilesList
                                    })}} className="button">Annuler</Link>
                            </div>
                        }
                        
                        {
                            this.state.showFilter &&
                            <div className="_Filter bg-white p-20 brs-10 d-flex jc-space-b m-b-10">
                                <div className="d-flex jc-space-b align-center">
                                    <span>
                                        Ordre : 
                                    </span>
                                    <ul className="d-flex jc-space-b">
                                        <li>
                                            <Link onClick={(e)=> {e.preventDefault(); this.setState({order : "asc"})}} className={this.state.order === "asc" ? " active " : "" }>
                                                <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={(e)=> {e.preventDefault(); this.setState({order : "desc"})}} className={this.state.order === "desc" ? " active " : "" }>
                                                <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex jc-space-b align-center">
                                    <span>
                                        Trier par : 
                                    </span>
                                    <ul className="d-flex jc-space-b">
                                        <li>
                                            <Link onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "name"})}} className={this.state.sortBy === "name" ? " active " : "" }>
                                                Nom
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "date"})}} className={this.state.sortBy === "date" ? " active " : "" }>
                                                Date
                                            </Link>
                                        </li>
                                        <li>
                                            <Link  onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "type"})}} className={this.state.sortBy === "type" ? " active " : "" }>
                                                Type
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }


                        


                        

                        {(this.state.loaded && this.state.filesList.length > 0) && 
                            <div className="bg-white p-20 brs-10">
                                <div className="_docs-list">
                                    <ul>
                                       
                                        
                                        {this.state.filesList
                                        .sort(function(a, b){
                                            return this.handleSort(a, b)
                                        }.bind(this))
                                        .slice(0, this.state.limit)
                                        .map(function(el, index){
                                            if(el.type === "folder"){
                                                return (
                                                    <li key={'folder-' + index} className="doc-item">
                                                        <div className="rm-list">
                                                            <input type="checkbox" id={"fld-" + el.id} />
                                                            <label className="toggle" htmlFor={"fld-" + el.id}>...</label>
                                                            <div className="rm-list-item">
                                                                <ul>
                                                                    <li>
                                                                        <Link to="/" onClick={(e) => {e.preventDefault(); this.setState({folderToDelete : el.id})}}>
                                                                            Supprimer
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="doc-info">
                                                            <div className="d-flex align-center">
                                                                <figure className="doc-icon">
                                                                    <Link to={"/mediatheque/" + el.id}>
                                                                        {/* <img src={configs.GetImage('icon-folder.png')} alt="" /> */}
                                                                        <i className="custom-icon custom-icon-folder"></i>
                                                                    </Link>
                                                                </figure>
                                                                <div className="">
                                                                    <span className="doc-name"><Link to={"/mediatheque/" + el.id}>{el.nom}</Link></span><br />
                                                                    {
                                                                        (el.nomauteur || el.prenomauteur) &&
                                                                        <span className="doc-author">{el.prenomauteur.toLowerCase()} {el.nomauteur.toLowerCase()}<br /></span>
                                                                    } 
                                                                    <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }else{
                                                const ext = el.document ? el.document.substring(el.document.lastIndexOf(".")+1).toLowerCase() : "txt";
                                                const fileType = configs.GetFileIcon(ext,true)
                                                return (
                                                    <li key={"file-" + index} className="doc-item">
                                                        <div className="rm-list">
                                                            <input type="checkbox" id={"fl-" + el.id} />
                                                            <label className="toggle" htmlFor={"fl-" + el.id}>...</label>
                                                            <div className="rm-list-item">
                                                                <ul>
                                                                    <li><a target={ext === "pdf" ? "_blank" : "_self"} 
                                                                        onClick={(e) => {
                                                                            configs.axiosDownloadFile(el.document, el.document)
                                                                        }} 
                                                                        style={{ cursor: 'pointer' }} 

                                                                        >Télécharger</a></li>
                                                                    {/* <li><a target={ext === "pdf" ? "_blank" : "_self"} href={Global.API_URL +"/" + el.document} download>Télécharger</a></li> */}
                                                                    <li><Link to="/" onClick={(e) => {e.preventDefault(); this.setState({fileToDelete : el.id})}} >Supprimer</Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {
                                                            (fileType == 'video' || fileType == 'image') &&
                                                            <div className="doc-info">
                                                                <div className="d-flex align-center">
                                                                    <figure className="doc-icon">
                                                                        <div  onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.document}})}}>
                                                                            <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.document} />         
                                                                        </div>
                                                                    </figure>
                                                                    <div className="">
                                                                        <span className="doc-name" onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.document}})}}>
                                                                            {el.documentreelname}
                                                                        </span><br />
                                                                        <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        }  

                                                        {
                                                            fileType == 'file' &&
                                                            <div className="doc-info">
                                                                <div className="d-flex align-center">
                                                                    <figure className="doc-icon">
                                                                        <a 
                                                                            onClick={(e) => {
                                                                                configs.axiosDownloadFile(el.document, el.document)
                                                                            }} 
                                                                            style={{ cursor: 'pointer' }} 
                                                                        >
                                                                        {/* <a href={Global.API_URL +"/" + el.document} target="_blank"> */}
                                                                            
                                                                            <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.document} />         
                                                                                
                                                                        </a>
                                                                    </figure>
                                                                    <div className="">
                                                                        <span className="doc-name"><a target={ext === "pdf" ? "_blank" : "_self"}
                                                                            onClick={(e) => {
                                                                                configs.axiosDownloadFile(el.document, el.document)
                                                                            }} 
                                                                            style={{ cursor: 'pointer' }} 
                                                                        >{el.documentreelname}</a></span><br />
                                                                        {/* <span className="doc-name"><a target={ext === "pdf" ? "_blank" : "_self"} href={Global.API_URL +"/" + el.document} download>{el.documentreelname}</a></span><br /> */}
                                                                        {/* <span className="doc-author">Evertt Carter</span><br /> */}
                                                                        <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </li>
                                                )
                                            }
                                        }.bind(this))}
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                        }

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de fichiers</span>
                                :
                                <span className="fa fa-spinner fa-spin"></span>
                            }
                        </div>


                        {this.state.addFolder && 
                            <div className="_modal AddFolder" id="AddFolder">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Entrer le nom de nouveau dossier </h2>
                                        </div>
                                        <div className="_modal-body f-wrap">
                                            <div className="p-10 w100">
                                                <div className="">
                                                    <InputField 
                                                        type="text" 
                                                        id="_foldername" 
                                                        name="foldername" 
                                                        label="" 
                                                        fixedLabel="false" 
                                                        classInput=""
                                                        placeholder="Nom de dossier"
                                                        classContainer={classnames({
                                                            " hasError ": this.state.errors.foldername,
                                                        })} 
                                                        value={this.state.foldername} 
                                                        oninput={this.handleInputChange} 
                                                        errorMsg={classnames({
                                                            "l'email est obligatoire et il doit respecter ce format example@email.com": this.state.errors.email,
                                                        })} />
                                                </div>
                                                {
                                                    this.state.apiError &&
                                                    <div style={{color: 'red'}}>
                                                        { this.state.apiError }
                                                    </div>
                                                } 
                                            </div>
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.handleAddFolder.bind(this)} ref={this.AddFolderBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.fileToDelete && 
                            <div className="_modal deleteFile confirmation" id="deleteFile">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette fichier&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmFileDeletion.bind(this)} ref={this.DeleteFileBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.folderToDelete && 
                            <div className="_modal deleteFile confirmation" id="deleteFile">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette dossier&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmFolderDeletion.bind(this)} ref={this.DeleteFolderBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.addFiles && 
                            <div className="_modal addFiles" id="addFiles">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Sélectionnez les fichiers à ajouter</h2>
                                        </div>
                                        <div className="_modal-body f-wrap">
                                            <div className="p-10 w100">
                                                {this.state.filesToAdd.length > 5 && 
                                                    <div>
                                                        <div className="_alert _alert-danger">
                                                            Il faut choisir maximum 5 fichiers
                                                        </div>
                                                        <br />
                                                    </div>
                                                }
                                                {this.state.filesToAddNames.length !== 0 &&
                                                    <div className="fileList">
                                                        {this.state.filesToAddNames.map(function(file, ind){
                                                            const ext = file ? file.substring(file.lastIndexOf(".")+1).toLowerCase() : "txt";
                                                            return (
                                                                <span key={"docx-"+ind}>
                                                                    <span className="remove" >
                                                                        <i  data-target={ind} onClick={this.removeFile.bind(this)}>&times;</i>&nbsp;
                                                                        <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={file} /> 
                                                                        &nbsp;&nbsp;{file}
                                                                    </span>
                                                                </span>
                                                            )
                                                        }.bind(this))}
                                                    </div>
                                                }

                                                <div className="addNewFiles">
                                                    <label htmlFor="_addFiles" className="button lg">
                                                        <span className="">
                                                            <i className="fa fa-plus _xlv"></i>
                                                        </span>&nbsp;&nbsp; {this.state.filesToAdd.length === 0 ? "Télécharger des fichiers" : "Ajouter d'autres fichiers"}
                                                    </label>
                                                    <input ref={this.InputFiles} type="file" className="_addFiles" onChange={this.handleDocxChange} id="_addFiles" multiple />
                                                </div>
                                                
                                                
                                                <div className="p-10">
                                                    <small>
                                                        * Maximum de 5 fichiers à télécharger
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            {this.state.uploadPercentage !== 0 && 
                                                <div className="loaderProgress">
                                                    <div className="progressBar">
                                                        <span style={{width: this.state.uploadPercentage + "%"}}></span>
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.handleAddFiles.bind(this)} ref={this.AddFilesBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {
                    this.state.lightBox.type === 'image' && this.state.lightBox.open &&
                    <Lightbox
                        mainSrc={this.state.lightBox.url} 
                        onCloseRequest={()=>{this.setState({lightBox:{open: false}})}}
                        reactModalStyle={{zIndex:100000}}  
                        animationDisabled={true}
                    />
                } 
                {   this.state.lightBox.type === 'video' && this.state.lightBox.open &&
                    <div className="ReactImageVideoLightbox">
                        <ReactImageVideoLightbox
                            data={[{ url: this.state.lightBox.url, type: 'video', altTag: '' }]}
                            startIndex={0}
                            showResourceCount={false}
                            onCloseCallback={()=>{this.setState({lightBox:{open: false}})}} 
                        />
                    </div>
                }
            </div>
        )
    }
}

Documents.propTypes = {
    auth : PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(Documents));