import Axios from 'axios';
import jwt_decode from 'jwt-decode'
import Global from './Global';

const configs = {
    GetImage: (path) => {
        return require('../assets/img/'+path);
    },
    GetFileIcon: (ext, returnType = false) =>{
        let fileName = null
        switch(ext){
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'gif':
            case 'tiff':
                fileName = {
                    icon: "icon-img.png",
                    type: 'image'
                }
                break
            case 'webm':
            case 'mpg':
            case 'mp2':
            case 'mpeg':
            case 'mpe':
            case 'mpv':
            case 'ogg':
            case 'mp4':
            case 'm4p':
            case 'm4v':
            case 'avi':
            case 'wmv':
            case 'mov':
            case 'qt':
            case 'flv':
            case 'swf':
            case 'avchd':
                fileName = {
                    icon: "icon-video.png",
                    type: 'video'
                }
                break 
            case 'doc':
            case 'docx':
                fileName = {
                    icon: "icon-word.png" ,
                    type: 'file'
                }
                break
            case 'xls':
            case 'xlt':
            case 'xlsx':
            case 'csv':
                fileName = {
                    icon: "icon-excel.png" ,
                    type: 'file'
                }
                break
            case 'pdf':  
                fileName = {
                    icon: "icon-pdf.png" ,
                    type: 'file'
                }
                break
            case 'ppt':
            case 'pptx': 
                fileName = {
                    icon: "icon-ppt.png" ,
                    type: 'file'
                }
                break
            case 'txt':  
                fileName = {
                    icon: "icon-file.png" ,
                    type: 'file'
                }
                break
            default: 
                fileName = {
                    icon: "icon-other-file.png" ,
                    type: 'file'
                }
        }
        return returnType ? fileName.type : fileName.icon;
    },
    getTokenCurrentUser : () => {
        if(localStorage.jwtToken){
            return localStorage.jwtToken
        }
        return false;
    },
    isAdmin : () => {
        if(localStorage.jwtToken){
            const decode = jwt_decode(localStorage.jwtToken);
            return decode.roles[0] === "ROLE_ADMIN" ? true : false;
        }
        return false;
    },
    getCurrentUser : () => {
        if(localStorage.jwtToken){
            const decode = JSON.parse(localStorage.getItem("jwtUd"))
            return decode;
            // {
            //     "iat": 1682248892,
            //     "exp": 1713805844,
            //     "roles": [
            //         "ROLE_ADMIN",
            //         "ROLE_USER"
            //     ],
            //     "username": "hamriabdo523@gmail.com",
            //     "profil": "/profile/60f045518b418.jpg",
            //     "name": "ABDELHAK",
            //     "lastname": "hamri",
            //     "user_id": 9,
            //     "create_communities": true
            // }
        }
        return false;
    },
    canCreateCommunity : () => {
        if(localStorage.getItem("jwtUd")){
            const decode = JSON.parse(localStorage.getItem("jwtUd"))
            return decode.create_communities;
        }
        return false;
    },
    timeSince : (date) => {
        if(!date) return ''
        if (typeof date !== 'object') {
            date = new Date(date);
        }
        var seconds = Math.floor((new Date() - date) / 1000);
        
        var interval = seconds / 86400;
        if (interval > 1) {
            const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
          return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear() + " à " + (date.getHours() >= 10 ? date.getHours() : "0"+date.getHours()) + ":" + (date.getMinutes().toString().length === 1 ? "0" + date.getMinutes() : date.getMinutes());
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return  Math.floor(interval) == 1 ? "Il y a une heure" : "Il y a " + Math.floor(interval) + " heures";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) == 1 ? "Il y a une minute": Math.floor(interval) + " minutes";
        }
        return "Il y a quelques secondes";
    },
    frenchDateFormat: (date)=>{
        if(!date) return;
        const event = new Date(date); 
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; 
        return event.toLocaleDateString('fr-FR', options)
    },
    frenchNumericDateFormat: (date)=>{
        if(!date) return;
        const event = new Date(date); 
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' }; 
        return event.toLocaleDateString('fr-FR', options)
    },
    getImageSize : async (url) => {
        var w; var h;
        var img=new Image();
        img.src=url;
        w = "";
        h = "";
        img.src = url;
        
        img.onload = await function(){
           w = this.width;
           h = this.height;
           return {width:w,height:h}
        };
        
    },
    getExtention : (filename) => {
        return filename.split('.').pop();
    },
    kFormatter : (num) => {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    getIndexWidthProperty : (array, attr, value) => {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    },
    getIndexWidthPropertyInsideProperty : (array, attr1,attr2, value) => {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr1][attr2] === value) {
                return i;
            }
        }
        return -1;
    },
    getIndexWidthManyProperties : (array, attrs, values, numberOfAttr) => {
        if(!Array.isArray(array)){
            return -1;
        }
        for(var i = 0; i < array.length; i += 1) {
            var count = 0;
            for(var j = 0; j < attrs.length; j++){
                if(array[i][attrs[j]] === values[j]) {
                    count++;
                }  
            }
            if( count === numberOfAttr ){
                return i;
            }
        }
        return -1;
    },
    getLanguages: ()=>{
        return[
            {label: 'Anglais',      value: 'Anglais',       code: 'en'},
            {label: 'Allemand',     value: 'Allemand',      code: 'de'},
            {label: 'Bengali',      value: 'Bengali',       code: 'bn'},
            {label: 'Chinois',      value: 'Chinois',       code: 'zh'},
            {label: 'Espagnol',     value: 'Espagnol',      code: 'es'},
            {label: 'Français',     value: 'Français',      code: 'fr'},
            {label: 'Italien',      value: 'Italien',       code: 'it'},
            {label: 'Turc',         value: 'Turc',          code: 'tr'},
            {label: 'Polonais',     value: 'Polonais',      code: 'pl'},
            {label: 'Portugais',    value: 'Portugais',     code: 'pt'},
            {label: 'Néerlandaise', value: 'Néerlandaise',  code: 'nl'}
        ] 
    },
    translatePostText: (text, langue_code)=>{
        return new Promise((resolve, reject)=>{
            fetch("https://translation.googleapis.com/language/translate/v2?key="+Global.GOOGLE_TRANSLATE_API_KEY,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    q: text,
                    target: langue_code,
                })
            }).then(res => res.json())
            .then(res=>{
                if( res.data) resolve( res.data.translations[0].translatedText );
                else reject(false)
            })
        }) 
    },
    axiosDownloadFile(url, fileName) {
        if (url.charAt(0) === '/') {
            url =  url.slice(1);
        }
        Axios.get(Global.API_URL + "/api/document/"+ url)
        .then(response => {
            const href = window.URL.createObjectURL(new Blob([response.data]));
            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = fileName; // Provide the desired file name here
            document.body.appendChild(anchorElement);
            anchorElement.click();
        
            // Clean up
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        })
        .catch(error => {
            console.error('error: ', error);
        });
    }

    
}

export default configs;